/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { UserModel } from '../models/user-model';

@Injectable({
  providedIn: 'root',
})
export class UsersService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation usersGetAll
   */
  static readonly UsersGetAllPath = '/api/Users';

  /**
   * List Of System Users.
   *
   * List Of System Users
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersGetAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersGetAll$Response(params?: {
  }): Observable<StrictHttpResponse<Array<UserModel>>> {

    const rb = new RequestBuilder(this.rootUrl, UsersService.UsersGetAllPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserModel>>;
      })
    );
  }

  /**
   * List Of System Users.
   *
   * List Of System Users
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `usersGetAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersGetAll(params?: {
  }): Observable<Array<UserModel>> {

    return this.usersGetAll$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserModel>>) => r.body as Array<UserModel>)
    );
  }

  /**
   * Path part for operation usersPost
   */
  static readonly UsersPostPath = '/api/Users';

  /**
   * Save User.
   *
   * Save User
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersPost$Response(params: {
    body: UserModel
  }): Observable<StrictHttpResponse<UserModel>> {

    const rb = new RequestBuilder(this.rootUrl, UsersService.UsersPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserModel>;
      })
    );
  }

  /**
   * Save User.
   *
   * Save User
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `usersPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersPost(params: {
    body: UserModel
  }): Observable<UserModel> {

    return this.usersPost$Response(params).pipe(
      map((r: StrictHttpResponse<UserModel>) => r.body as UserModel)
    );
  }

  /**
   * Path part for operation usersGet
   */
  static readonly UsersGetPath = '/api/Users/{id}';

  /**
   * Get User By Id.
   *
   * Get User By Id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersGet$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<UserModel>> {

    const rb = new RequestBuilder(this.rootUrl, UsersService.UsersGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserModel>;
      })
    );
  }

  /**
   * Get User By Id.
   *
   * Get User By Id
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `usersGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersGet(params: {
    id: string;
  }): Observable<UserModel> {

    return this.usersGet$Response(params).pipe(
      map((r: StrictHttpResponse<UserModel>) => r.body as UserModel)
    );
  }

  /**
   * Path part for operation usersDelete
   */
  static readonly UsersDeletePath = '/api/Users/{id}';

  /**
   * Delete User.
   *
   * Delete User
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersDelete$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, UsersService.UsersDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Delete User.
   *
   * Delete User
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `usersDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersDelete(params: {
    id: string;
  }): Observable<boolean> {

    return this.usersDelete$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation usersGetRoles
   */
  static readonly UsersGetRolesPath = '/api/Users/roles';

  /**
   * List Of System Users.
   *
   * List Of System Users
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersGetRoles()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersGetRoles$Response(params?: {
  }): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, UsersService.UsersGetRolesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * List Of System Users.
   *
   * List Of System Users
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `usersGetRoles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersGetRoles(params?: {
  }): Observable<Array<string>> {

    return this.usersGetRoles$Response(params).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

  /**
   * Path part for operation usersUpdateRoles
   */
  static readonly UsersUpdateRolesPath = '/api/Users/update-roles';

  /**
   * Update System Roles.
   *
   * Update System Roles
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersUpdateRoles()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersUpdateRoles$Response(params?: {
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, UsersService.UsersUpdateRolesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Update System Roles.
   *
   * Update System Roles
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `usersUpdateRoles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersUpdateRoles(params?: {
  }): Observable<boolean> {

    return this.usersUpdateRoles$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation usersGetTwoFactorKey
   */
  static readonly UsersGetTwoFactorKeyPath = '/api/Users/get-2fa/{id}';

  /**
   * Get Two Factor Key.
   *
   * Get Two Factor Key
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersGetTwoFactorKey()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersGetTwoFactorKey$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, UsersService.UsersGetTwoFactorKeyPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Get Two Factor Key.
   *
   * Get Two Factor Key
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `usersGetTwoFactorKey$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersGetTwoFactorKey(params: {
    id: string;
  }): Observable<string> {

    return this.usersGetTwoFactorKey$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

}
