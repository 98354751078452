/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { LoginModel } from '../models/login-model';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation authLogin
   */
  static readonly AuthLoginPath = '/api/Auth/login';

  /**
   * Login to the system.
   *
   * Login to the system
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authLogin()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authLogin$Response(params: {
    body: LoginModel
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, AuthService.AuthLoginPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Login to the system.
   *
   * Login to the system
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `authLogin$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authLogin(params: {
    body: LoginModel
  }): Observable<string> {

    return this.authLogin$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation authCode
   */
  static readonly AuthCodePath = '/api/Auth/code';

  /**
   * Login to the system.
   *
   * Login to the system
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authCode()` instead.
   *
   * This method doesn't expect any request body.
   */
  authCode$Response(params?: {
    code?: string;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, AuthService.AuthCodePath, 'post');
    if (params) {
      rb.query('code', params.code, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Login to the system.
   *
   * Login to the system
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `authCode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authCode(params?: {
    code?: string;
  }): Observable<string> {

    return this.authCode$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation authRefresh
   */
  static readonly AuthRefreshPath = '/api/Auth/refresh';

  /**
   * Refresh User Token.
   *
   * Refresh User Token
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authRefresh()` instead.
   *
   * This method doesn't expect any request body.
   */
  authRefresh$Response(params?: {
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, AuthService.AuthRefreshPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Refresh User Token.
   *
   * Refresh User Token
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `authRefresh$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authRefresh(params?: {
  }): Observable<string> {

    return this.authRefresh$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation authLogout
   */
  static readonly AuthLogoutPath = '/api/Auth/sign-out';

  /**
   * Logout of the system.
   *
   * Logout of the system
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authLogout()` instead.
   *
   * This method doesn't expect any request body.
   */
  authLogout$Response(params?: {
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AuthService.AuthLogoutPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Logout of the system.
   *
   * Logout of the system
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `authLogout$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authLogout(params?: {
  }): Observable<boolean> {

    return this.authLogout$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation authPing
   */
  static readonly AuthPingPath = '/api/Auth/ping';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authPing()` instead.
   *
   * This method doesn't expect any request body.
   */
  authPing$Response(params?: {
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, AuthService.AuthPingPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `authPing$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authPing(params?: {
  }): Observable<string> {

    return this.authPing$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

}
