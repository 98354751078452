/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { OpenmhzProfile } from '../models/openmhz-profile';
import { OpenmhzProfilesGridResult } from '../models/openmhz-profiles-grid-result';
import { OpenmhzProfilesRequestModel } from '../models/openmhz-profiles-request-model';

@Injectable({
  providedIn: 'root',
})
export class OpenmhzScannersService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation openmhzScannersGetOpenmhzProfiles
   */
  static readonly OpenmhzScannersGetOpenmhzProfilesPath = '/api/OpenmhzScanners/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `openmhzScannersGetOpenmhzProfiles()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  openmhzScannersGetOpenmhzProfiles$Response(params: {
    body: OpenmhzProfilesRequestModel
  }): Observable<StrictHttpResponse<OpenmhzProfilesGridResult>> {

    const rb = new RequestBuilder(this.rootUrl, OpenmhzScannersService.OpenmhzScannersGetOpenmhzProfilesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OpenmhzProfilesGridResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `openmhzScannersGetOpenmhzProfiles$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  openmhzScannersGetOpenmhzProfiles(params: {
    body: OpenmhzProfilesRequestModel
  }): Observable<OpenmhzProfilesGridResult> {

    return this.openmhzScannersGetOpenmhzProfiles$Response(params).pipe(
      map((r: StrictHttpResponse<OpenmhzProfilesGridResult>) => r.body as OpenmhzProfilesGridResult)
    );
  }

  /**
   * Path part for operation openmhzScannersGet
   */
  static readonly OpenmhzScannersGetPath = '/api/OpenmhzScanners/{id}';

  /**
   * Get radio scanner By Id.
   *
   * Get radio scanner By Id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `openmhzScannersGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  openmhzScannersGet$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<OpenmhzProfile>> {

    const rb = new RequestBuilder(this.rootUrl, OpenmhzScannersService.OpenmhzScannersGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OpenmhzProfile>;
      })
    );
  }

  /**
   * Get radio scanner By Id.
   *
   * Get radio scanner By Id
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `openmhzScannersGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  openmhzScannersGet(params: {
    id: number;
  }): Observable<OpenmhzProfile> {

    return this.openmhzScannersGet$Response(params).pipe(
      map((r: StrictHttpResponse<OpenmhzProfile>) => r.body as OpenmhzProfile)
    );
  }

  /**
   * Path part for operation openmhzScannersDelete
   */
  static readonly OpenmhzScannersDeletePath = '/api/OpenmhzScanners/{id}';

  /**
   * Delete radio scanner By Id.
   *
   * Delete radio scanner By Id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `openmhzScannersDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  openmhzScannersDelete$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, OpenmhzScannersService.OpenmhzScannersDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Delete radio scanner By Id.
   *
   * Delete radio scanner By Id
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `openmhzScannersDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  openmhzScannersDelete(params: {
    id: number;
  }): Observable<boolean> {

    return this.openmhzScannersDelete$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation openmhzScannersPost
   */
  static readonly OpenmhzScannersPostPath = '/api/OpenmhzScanners';

  /**
   * Save Openmhz Configuration.
   *
   * Save Openmhz Configuration
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `openmhzScannersPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  openmhzScannersPost$Response(params: {
    body: OpenmhzProfile
  }): Observable<StrictHttpResponse<OpenmhzProfile>> {

    const rb = new RequestBuilder(this.rootUrl, OpenmhzScannersService.OpenmhzScannersPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OpenmhzProfile>;
      })
    );
  }

  /**
   * Save Openmhz Configuration.
   *
   * Save Openmhz Configuration
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `openmhzScannersPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  openmhzScannersPost(params: {
    body: OpenmhzProfile
  }): Observable<OpenmhzProfile> {

    return this.openmhzScannersPost$Response(params).pipe(
      map((r: StrictHttpResponse<OpenmhzProfile>) => r.body as OpenmhzProfile)
    );
  }

}
