/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AccountsAgingModel } from '../models/accounts-aging-model';
import { ExpenseDetailsModel } from '../models/expense-details-model';
import { InvoiceMonthSummary } from '../models/invoice-month-summary';
import { InvoicesGridResult } from '../models/invoices-grid-result';
import { InvoicesRequestModel } from '../models/invoices-request-model';
import { SyncInvoicesResult } from '../models/sync-invoices-result';

@Injectable({
  providedIn: 'root',
})
export class FreshbooksService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation freshbooksAuthorizeFreshbooks
   */
  static readonly FreshbooksAuthorizeFreshbooksPath = '/api/Freshbooks/authorize';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `freshbooksAuthorizeFreshbooks()` instead.
   *
   * This method doesn't expect any request body.
   */
  freshbooksAuthorizeFreshbooks$Response(params?: {
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, FreshbooksService.FreshbooksAuthorizeFreshbooksPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `freshbooksAuthorizeFreshbooks$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  freshbooksAuthorizeFreshbooks(params?: {
  }): Observable<void> {

    return this.freshbooksAuthorizeFreshbooks$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation freshbooksFreshbooksCode
   */
  static readonly FreshbooksFreshbooksCodePath = '/api/Freshbooks/callback';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `freshbooksFreshbooksCode()` instead.
   *
   * This method doesn't expect any request body.
   */
  freshbooksFreshbooksCode$Response(params?: {
    code?: string;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, FreshbooksService.FreshbooksFreshbooksCodePath, 'get');
    if (params) {
      rb.query('code', params.code, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `freshbooksFreshbooksCode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  freshbooksFreshbooksCode(params?: {
    code?: string;
  }): Observable<string> {

    return this.freshbooksFreshbooksCode$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation freshbooksGetInvoices
   */
  static readonly FreshbooksGetInvoicesPath = '/api/Freshbooks/invoices';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `freshbooksGetInvoices()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  freshbooksGetInvoices$Response(params: {
    body: InvoicesRequestModel
  }): Observable<StrictHttpResponse<InvoicesGridResult>> {

    const rb = new RequestBuilder(this.rootUrl, FreshbooksService.FreshbooksGetInvoicesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvoicesGridResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `freshbooksGetInvoices$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  freshbooksGetInvoices(params: {
    body: InvoicesRequestModel
  }): Observable<InvoicesGridResult> {

    return this.freshbooksGetInvoices$Response(params).pipe(
      map((r: StrictHttpResponse<InvoicesGridResult>) => r.body as InvoicesGridResult)
    );
  }

  /**
   * Path part for operation freshbooksSyncInvoices
   */
  static readonly FreshbooksSyncInvoicesPath = '/api/Freshbooks/sync';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `freshbooksSyncInvoices()` instead.
   *
   * This method doesn't expect any request body.
   */
  freshbooksSyncInvoices$Response(params?: {
  }): Observable<StrictHttpResponse<SyncInvoicesResult>> {

    const rb = new RequestBuilder(this.rootUrl, FreshbooksService.FreshbooksSyncInvoicesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SyncInvoicesResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `freshbooksSyncInvoices$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  freshbooksSyncInvoices(params?: {
  }): Observable<SyncInvoicesResult> {

    return this.freshbooksSyncInvoices$Response(params).pipe(
      map((r: StrictHttpResponse<SyncInvoicesResult>) => r.body as SyncInvoicesResult)
    );
  }

  /**
   * Path part for operation freshbooksInvoiceDetailsReport
   */
  static readonly FreshbooksInvoiceDetailsReportPath = '/api/Freshbooks/invoice-details-report';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `freshbooksInvoiceDetailsReport()` instead.
   *
   * This method doesn't expect any request body.
   */
  freshbooksInvoiceDetailsReport$Response(params?: {
  }): Observable<StrictHttpResponse<Array<InvoiceMonthSummary>>> {

    const rb = new RequestBuilder(this.rootUrl, FreshbooksService.FreshbooksInvoiceDetailsReportPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<InvoiceMonthSummary>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `freshbooksInvoiceDetailsReport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  freshbooksInvoiceDetailsReport(params?: {
  }): Observable<Array<InvoiceMonthSummary>> {

    return this.freshbooksInvoiceDetailsReport$Response(params).pipe(
      map((r: StrictHttpResponse<Array<InvoiceMonthSummary>>) => r.body as Array<InvoiceMonthSummary>)
    );
  }

  /**
   * Path part for operation freshbooksAccountsAgingReport
   */
  static readonly FreshbooksAccountsAgingReportPath = '/api/Freshbooks/accounts-aging-report';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `freshbooksAccountsAgingReport()` instead.
   *
   * This method doesn't expect any request body.
   */
  freshbooksAccountsAgingReport$Response(params?: {
  }): Observable<StrictHttpResponse<AccountsAgingModel>> {

    const rb = new RequestBuilder(this.rootUrl, FreshbooksService.FreshbooksAccountsAgingReportPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountsAgingModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `freshbooksAccountsAgingReport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  freshbooksAccountsAgingReport(params?: {
  }): Observable<AccountsAgingModel> {

    return this.freshbooksAccountsAgingReport$Response(params).pipe(
      map((r: StrictHttpResponse<AccountsAgingModel>) => r.body as AccountsAgingModel)
    );
  }

  /**
   * Path part for operation freshbooksExpenseDetailsReport
   */
  static readonly FreshbooksExpenseDetailsReportPath = '/api/Freshbooks/expense-details-report';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `freshbooksExpenseDetailsReport()` instead.
   *
   * This method doesn't expect any request body.
   */
  freshbooksExpenseDetailsReport$Response(params?: {
  }): Observable<StrictHttpResponse<ExpenseDetailsModel>> {

    const rb = new RequestBuilder(this.rootUrl, FreshbooksService.FreshbooksExpenseDetailsReportPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExpenseDetailsModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `freshbooksExpenseDetailsReport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  freshbooksExpenseDetailsReport(params?: {
  }): Observable<ExpenseDetailsModel> {

    return this.freshbooksExpenseDetailsReport$Response(params).pipe(
      map((r: StrictHttpResponse<ExpenseDetailsModel>) => r.body as ExpenseDetailsModel)
    );
  }

}
