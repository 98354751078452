import {Injectable} from '@angular/core';
import {AuthService as ApiAuthService} from '../api/services';
import {BehaviorSubject, Observable} from 'rxjs';
import {Router} from '@angular/router';

export type IAuthenticationResult =
  SuccessAuthenticationResult |
  FailureAuthenticationResult |
  RedirectAuthenticationResult;

export interface SuccessAuthenticationResult {
  status: AuthenticationResultStatus.Success;
  state: any;
}

export interface FailureAuthenticationResult {
  status: AuthenticationResultStatus.Fail;
  message: string;
}

export interface RedirectAuthenticationResult {
  status: AuthenticationResultStatus.Redirect;
}

export enum AuthenticationResultStatus {
  Success,
  Redirect,
  Fail
}

export interface IUser extends IJWTModel {
  name?: string;
  access_token?: string;
}

export interface IJWTModel {
  role: string;
  ipAddress: string;
  aud: string;
  iss: string;
  iat: number;
  exp: number;
  nbf: number;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // By default pop ups are disabled because they don't work properly on Edge.
  // If you want to enable pop up authentication simply set this flag to false.

  static currentUserKey = `${AuthService.name}_currentUser`;
  private userSubject: BehaviorSubject<IUser | null> = new BehaviorSubject(null);

  constructor(private readonly apiAuthService: ApiAuthService, private router: Router) {
  }

  public async isAuthenticated(): Promise<boolean> {
    return !!await this.getUser();
  }

  public async getUser(): Promise<IUser | null> {
    await this.ensureUserManagerInitialized();
    return this.userSubject.getValue();
  }

  public async getAccessToken(): Promise<string> {
    const user = await this.getUser();
    return user?.access_token;
  }

  public async signIn(username: string, password: string, state: any): Promise<IAuthenticationResult> {
    await this.ensureUserManagerInitialized();

    try {
      const token = await this.apiAuthService.authLogin({body: {username: username, password: password}}).toPromise();
      this.saveUserInfo(token);
      if (token === 'enter-code') {
        await this.router.navigate(['auth', 'code'], {queryParams: {returnUrl: state.returnUrl}});
        return this.redirect();
      }
      return this.success('ok');
    } catch (silentError) {
      // User might not be authenticated, fallback to popup authentication
      console.log('Silent authentication error: ', silentError);
      return this.error('There was an error signing in.');
    }
  }

  public async signInCode(code: string, state: any): Promise<IAuthenticationResult> {
    await this.ensureUserManagerInitialized();

    try {
      const token = await this.apiAuthService.authCode({code: code}).toPromise();
      if (!token) {
        return this.error('There was an error signing in.');
      }
      this.saveUserInfo(token);
      return this.success('ok');
    } catch (silentError) {
      // User might not be authenticated, fallback to popup authentication
      console.log('Silent authentication error: ', silentError);
      return this.error('There was an error signing in.');
    }
  }

  public saveUserInfo(token: string): void {
    if (token !== 'enter-code') {
      const user: IUser = this.parseJwt(token);
      localStorage.setItem(AuthService.currentUserKey, token);
      user.access_token = token;
      this.userSubject.next(user);
    }
  }

  public async signOut() {
    localStorage.removeItem(AuthService.currentUserKey);
    this.userSubject.next(null);

    await this.router.navigate(['auth', 'login']);

    await this.apiAuthService.authLogout().toPromise();
  }

  public isTokenExpired(token: string): boolean {
    const jwt = this.parseJwt(token);
    const now = new Date();

    return now.getTime() < (jwt.exp * 1000);
  }

  public refreshToken(): Observable<string> {
    return this.apiAuthService.authRefresh();
  }

  public async completeSignOut(url: string): Promise<IAuthenticationResult> {
    await this.ensureUserManagerInitialized();
    try {
      // const response = await this.userManager.signoutCallback(url);
      this.userSubject.next(null);
      return this.success(/*response && response.state*/'ok');
    } catch (error) {
      console.log(`There was an error trying to log out '${error}'.`);
      return this.error(error);
    }
  }

  private createArguments(state?: any): any {
    return {useReplaceToNavigate: true, data: state};
  }

  private error(message: string): IAuthenticationResult {
    return {status: AuthenticationResultStatus.Fail, message};
  }

  private success(state: any): IAuthenticationResult {
    return {status: AuthenticationResultStatus.Success, state};
  }

  private redirect(): IAuthenticationResult {
    return {status: AuthenticationResultStatus.Redirect};
  }

  private async ensureUserManagerInitialized(): Promise<void> {
    const token = localStorage.getItem(AuthService.currentUserKey);
    if (token) {
      const user: IUser = this.parseJwt(token);
      user.access_token = token;
      this.userSubject.next(user);
    }
  }

  private parseJwt(token): IJWTModel {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

  async userHasPermission(permissions: string[]) {
    const user = await this.getUser();

    for (const permission of permissions) {
      if (user.role.includes(permission)) {
        return true;
      }
    }

    return false;
  }
}
