/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { RdioProfile } from '../models/rdio-profile';
import { RdioProfilesGridResult } from '../models/rdio-profiles-grid-result';
import { RdioProfilesRequestModel } from '../models/rdio-profiles-request-model';

@Injectable({
  providedIn: 'root',
})
export class RdioScannersService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation rdioScannersGetRdioProfiles
   */
  static readonly RdioScannersGetRdioProfilesPath = '/api/RdioScanners/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `rdioScannersGetRdioProfiles()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  rdioScannersGetRdioProfiles$Response(params: {
    body: RdioProfilesRequestModel
  }): Observable<StrictHttpResponse<RdioProfilesGridResult>> {

    const rb = new RequestBuilder(this.rootUrl, RdioScannersService.RdioScannersGetRdioProfilesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RdioProfilesGridResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `rdioScannersGetRdioProfiles$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  rdioScannersGetRdioProfiles(params: {
    body: RdioProfilesRequestModel
  }): Observable<RdioProfilesGridResult> {

    return this.rdioScannersGetRdioProfiles$Response(params).pipe(
      map((r: StrictHttpResponse<RdioProfilesGridResult>) => r.body as RdioProfilesGridResult)
    );
  }

  /**
   * Path part for operation rdioScannersGet
   */
  static readonly RdioScannersGetPath = '/api/RdioScanners/{id}';

  /**
   * Get radio scanner By Id.
   *
   * Get radio scanner By Id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `rdioScannersGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  rdioScannersGet$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<RdioProfile>> {

    const rb = new RequestBuilder(this.rootUrl, RdioScannersService.RdioScannersGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RdioProfile>;
      })
    );
  }

  /**
   * Get radio scanner By Id.
   *
   * Get radio scanner By Id
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `rdioScannersGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  rdioScannersGet(params: {
    id: number;
  }): Observable<RdioProfile> {

    return this.rdioScannersGet$Response(params).pipe(
      map((r: StrictHttpResponse<RdioProfile>) => r.body as RdioProfile)
    );
  }

  /**
   * Path part for operation rdioScannersDelete
   */
  static readonly RdioScannersDeletePath = '/api/RdioScanners/{id}';

  /**
   * Delete radio scanner By Id.
   *
   * Delete radio scanner By Id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `rdioScannersDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  rdioScannersDelete$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, RdioScannersService.RdioScannersDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Delete radio scanner By Id.
   *
   * Delete radio scanner By Id
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `rdioScannersDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  rdioScannersDelete(params: {
    id: number;
  }): Observable<boolean> {

    return this.rdioScannersDelete$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation rdioScannersPost
   */
  static readonly RdioScannersPostPath = '/api/RdioScanners';

  /**
   * Save Rdio Configuration.
   *
   * Save Rdio Configuration
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `rdioScannersPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  rdioScannersPost$Response(params: {
    body: RdioProfile
  }): Observable<StrictHttpResponse<RdioProfile>> {

    const rb = new RequestBuilder(this.rootUrl, RdioScannersService.RdioScannersPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RdioProfile>;
      })
    );
  }

  /**
   * Save Rdio Configuration.
   *
   * Save Rdio Configuration
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `rdioScannersPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  rdioScannersPost(params: {
    body: RdioProfile
  }): Observable<RdioProfile> {

    return this.rdioScannersPost$Response(params).pipe(
      map((r: StrictHttpResponse<RdioProfile>) => r.body as RdioProfile)
    );
  }

}
