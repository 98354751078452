import {Injectable} from '@angular/core';
import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse} from '@angular/common/http';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {AuthService} from './auth.service';
import {catchError, filter, mergeMap, switchMap, take} from 'rxjs/operators';
import {fromPromise} from 'rxjs/internal-compatibility';
import {MessageService} from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private authorize: AuthService, private messageService: MessageService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return fromPromise(this.authorize.getAccessToken())
      .pipe(mergeMap(token => this.processRequestWithToken(token, request, next)));
  }

  private setRequestHeaders(token: string, request: HttpRequest<any>) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  // Checks if there is an access_token available in the authorize service
  // and adds it to the request in case it's targeted at the same origin as the
  // single page application.
  private processRequestWithToken(token: string, request: HttpRequest<any>, next: HttpHandler) {
    if (!!token && this.isSameOriginUrl(request)) {
      request = this.setRequestHeaders(token, request);
    }

    return next.handle(request)
      .pipe(
        catchError((error: any) => {
          console.log(error);
          if (error instanceof HttpErrorResponse && error.status === 401) {
            if (this.isRefreshing) {
              this.isRefreshing = false;
              this.authorize.signOut();
              return of(error);
            }
            return this.tryAuthWithRefreshToken(request, next, error);
          } else {
            const errorMessage = error.error?.title ??  error.message;
            this.messageService.add({
              key: 'main',
              closable: true,
              summary: 'Error',
              detail: errorMessage,
              severity: 'error',
            });
            throw error;
          }
        })
      );
  }

  private tryAuthWithRefreshToken(request: HttpRequest<any>, next: HttpHandler, error: any) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authorize.refreshToken().pipe(
        switchMap((authToken: string) => {
          this.isRefreshing = false;
          if (authToken) {
            this.refreshTokenSubject.next(authToken);
            this.authorize.saveUserInfo(authToken);
            request = this.setRequestHeaders(authToken, request);
            return next.handle(request);
          } else {
            this.authorize.signOut();
            return of(error);
          }
        }));
    } else {
      return this.refreshTokenSubject.pipe(
        filter(authResult => authResult != null),
        take(1),
        switchMap(authResult => {
          request = this.setRequestHeaders(authResult, request);
          return next.handle(request);
        }));
    }
  }

  private isSameOriginUrl(req: any) {
    // It's an absolute url with the same origin.
    if (req.url.startsWith(`${window.location.origin}/`)) {
      return true;
    }

    // It's a protocol relative url with the same origin.
    // For example: //www.example.com/api/Products
    if (req.url.startsWith(`//${window.location.host}/`)) {
      return true;
    }

    // It's a relative url like /api/Products
    if (/^\/[^\/].*/.test(req.url)) {
      return true;
    }

    // It's an absolute or protocol relative url that
    // doesn't have the same origin.
    return false;
  }
}
