/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { RadioScannerServer } from '../models/radio-scanner-server';
import { RadioScannersGridResult } from '../models/radio-scanners-grid-result';
import { RadioScannersRequestModel } from '../models/radio-scanners-request-model';

@Injectable({
  providedIn: 'root',
})
export class RadioScannersService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation radioScannersGetRadioScanners
   */
  static readonly RadioScannersGetRadioScannersPath = '/api/RadioScanners/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `radioScannersGetRadioScanners()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  radioScannersGetRadioScanners$Response(params: {
    body: RadioScannersRequestModel
  }): Observable<StrictHttpResponse<RadioScannersGridResult>> {

    const rb = new RequestBuilder(this.rootUrl, RadioScannersService.RadioScannersGetRadioScannersPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RadioScannersGridResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `radioScannersGetRadioScanners$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  radioScannersGetRadioScanners(params: {
    body: RadioScannersRequestModel
  }): Observable<RadioScannersGridResult> {

    return this.radioScannersGetRadioScanners$Response(params).pipe(
      map((r: StrictHttpResponse<RadioScannersGridResult>) => r.body as RadioScannersGridResult)
    );
  }

  /**
   * Path part for operation radioScannersGet
   */
  static readonly RadioScannersGetPath = '/api/RadioScanners/{id}';

  /**
   * Get radio scanner By Id.
   *
   * Get radio scanner By Id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `radioScannersGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  radioScannersGet$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<RadioScannerServer>> {

    const rb = new RequestBuilder(this.rootUrl, RadioScannersService.RadioScannersGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RadioScannerServer>;
      })
    );
  }

  /**
   * Get radio scanner By Id.
   *
   * Get radio scanner By Id
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `radioScannersGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  radioScannersGet(params: {
    id: number;
  }): Observable<RadioScannerServer> {

    return this.radioScannersGet$Response(params).pipe(
      map((r: StrictHttpResponse<RadioScannerServer>) => r.body as RadioScannerServer)
    );
  }

  /**
   * Path part for operation radioScannersDelete
   */
  static readonly RadioScannersDeletePath = '/api/RadioScanners/{id}';

  /**
   * Delete radio scanner By Id.
   *
   * Delete radio scanner By Id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `radioScannersDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  radioScannersDelete$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, RadioScannersService.RadioScannersDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Delete radio scanner By Id.
   *
   * Delete radio scanner By Id
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `radioScannersDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  radioScannersDelete(params: {
    id: number;
  }): Observable<boolean> {

    return this.radioScannersDelete$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation radioScannersPost
   */
  static readonly RadioScannersPostPath = '/api/RadioScanners';

  /**
   * Save User.
   *
   * Save User
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `radioScannersPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  radioScannersPost$Response(params: {
    body: RadioScannerServer
  }): Observable<StrictHttpResponse<RadioScannerServer>> {

    const rb = new RequestBuilder(this.rootUrl, RadioScannersService.RadioScannersPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RadioScannerServer>;
      })
    );
  }

  /**
   * Save User.
   *
   * Save User
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `radioScannersPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  radioScannersPost(params: {
    body: RadioScannerServer
  }): Observable<RadioScannerServer> {

    return this.radioScannersPost$Response(params).pipe(
      map((r: StrictHttpResponse<RadioScannerServer>) => r.body as RadioScannerServer)
    );
  }

}
