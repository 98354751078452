import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {AppComponent} from './app.component';
import {AuthInterceptor} from './auth/auth.interceptor';
import {ApiModule} from './api/api.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ButtonModule} from 'primeng/button';
import {SplitButtonModule} from 'primeng/splitbutton';
import {ToolbarModule} from 'primeng/toolbar';
import {SidebarModule} from 'primeng/sidebar';
import {RippleModule} from 'primeng/ripple';
import {DynamicDialogModule} from 'primeng/dynamicdialog';
import {CommonModule} from '@angular/common';
import {CardModule} from 'primeng/card';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ApiConfiguration} from './api/api-configuration';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import {MessageService} from 'primeng/api';
import {MessagesModule} from 'primeng/messages';
import {environment} from '../environments/environment';
import {NgxEchartsModule} from 'ngx-echarts';
import {MatNativeDateModule} from '@angular/material/core';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'), // or import('./path-to-my-custom-echarts')
    }),
    ApiModule,
    RouterModule.forRoot([
      {path: '', loadChildren: () => import('./home/home.module').then(m => m.HomeModule)},
      {path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)},
    ], {relativeLinkResolution: 'corrected', enableTracing: true}),
    ButtonModule,
    SplitButtonModule,
    ToolbarModule,
    SidebarModule,
    DynamicDialogModule,
    RippleModule,
    CardModule,
    CommonModule,
    MatNativeDateModule,
    ConfirmDialogModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    MessagesModule
  ],
  providers: [
    MessageService,
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private apiConfig: ApiConfiguration) {
    apiConfig.rootUrl = environment.rootUrl;
  }
}
